import React from "react"
import Styled from "./styled"

const HeroFooter = () => {
  return (
    <Styled.Footer>
      <h2>Graphic</h2>
      <h2>&</h2>
      <h2>Design</h2>
    </Styled.Footer>
  )
}

export default HeroFooter