import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Hero from "./hero"
import Styled from "./styled"

const Intro = () => {

  const data = useStaticQuery(graphql`
  query Homepage {
    prismicHomepage {
      data {

        introduction {
          html
        }

      }
    }
  }
`)

const content = data.prismicHomepage.data

  return (
    <>
      <Hero />
      <Styled.Introduction>
        <div dangerouslySetInnerHTML={{ __html: content.introduction.html }} />
      </Styled.Introduction>
    </>
  )
}

export default Intro