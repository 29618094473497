import React from "react"
import ProjectTeaser from "../project-teaser-featured"
import Styled from "./styled"

const ProjectsFeatured = props => {

  const allProjects = props.input.projects && props.input.projects.map((node, index) => {
    const nodeData = node.project.document["0"].data
    return (
      <ProjectTeaser
        target={ node.project.document["0"].uid }
        title={ nodeData.project_title.text }
        category={ nodeData.category.document["0"].data.category_title.text }
        textColorMobile={ nodeData.teaser_text_color_inside_cards_mobile }
        year={ nodeData.year.text }
        imageUrl={ nodeData.teaser_image.large.url }
        imageAlt={ nodeData.teaser_image.alt }
        imageUrlLarge={ nodeData.teaser_image.large.url }
        imageUrlMedium={ nodeData.teaser_image.medium.url }
        imageUrlAutoHeight={ nodeData.teaser_image.mobileAutoHeight.url }
        dotColor={ nodeData.teaser_color }
        vimeoVideoId={ nodeData.vimeo_video_id?.text?.length >= 1 ? nodeData.vimeo_video_id?.text : '' }
      />
    )
  })

  return (
    <Styled.Grid>
      { allProjects }
    </Styled.Grid>
  )
}

export default ProjectsFeatured