import React from "react"
import { ThemeProvider } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import LayoutDefault from "../layouts/default"
import Intro from "../components/intro"
import ProjectsFeatured from "../components/projects-featured"
import Footer from "../components/footer"
import Navigation from "../components/navigation"

const IndexPage = () => {

  const data = useStaticQuery(graphql`
  query HomepageQuery {
    prismicHomepage {
      data {

        projects {
          project {
            document {
              uid
              data {
                project_title {
                  text
                }
                teaser_image {
                  alt
                  large {
                    url
                  }
                  medium {
                    url
                  }
                  mobileAutoHeight {
                    url
                  }
                }
                vimeo_video_id {
                  text
                }
                teaser_color
                teaser_text_color_inside_cards_mobile
                year {
                  text
                }
                category {
                  document {
                    data {
                      category_title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }

      }
    }
  }  
`)

  const content = data.prismicHomepage.data
  console.log(content)

  return (
    <ThemeProvider theme={{ mode: "light" }}>
      <LayoutDefault>
        {/* <Navigation /> */}
        <Intro />
        <ProjectsFeatured input={ content } />
        <Footer />
      </LayoutDefault>
    </ThemeProvider>
  )
}

export default IndexPage
