import React, { useState, useEffect } from "react"
import { CarouselProvider, Slider, Slide } from "pure-react-carousel"
import { useStaticQuery, graphql } from "gatsby"
import Styled from "./styled"
import themeSettings from "../../styles/theme"
import HeroFooter from "./hero-footer"
import Arrow from "../arrow"

const Hero = () => {
  const [vimeoAccepted, setVimeoAccepted] = useState(false)

  useEffect(() => {
    if (window.localStorage.getItem("fb-cookies-accepted") === "true") {
      setVimeoAccepted(true)
    } else {
      setVimeoAccepted(false)
    }
  }, [])

  const [heroHeight, setHeroHeight] = useState(null)
  const [heroPointerEvents, setHeroPointerEvents] = useState("none")
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentZIndex, setCurrentZIndex] = useState(1)
  const [cursor, setCursor] = useState(false)
  const [cursorPosition, setCursorPosition] = useState({
    x: 0,
    y: 0,
  })

  useEffect(() => {
    setTimeout(() => {
      setHeroPointerEvents("all")
    }, 1600)
    const defineDesktopHeight = () => {
      if (window.innerWidth > 415) {
        setHeroHeight(window.innerHeight - themeSettings.navHeight)
      }
    }
    const defineMobileHeight = () => {
      if (window.innerWidth <= 414) {
        setHeroHeight(window.innerHeight - themeSettings.mobileNavHeight)
      }
    }
    defineMobileHeight()
    defineDesktopHeight()
    window.addEventListener("resize", () => {
      defineDesktopHeight()
    })
    return () => {
      window.removeEventListener("resize", () => {
        defineDesktopHeight()
      })
    }
  }, [])

  const goToNextSlide = () => {
    if (currentIndex === allImages.length - 1) {
      return setCurrentIndex(0)
    }
    setCurrentIndex(currentIndex + 1)
    setCurrentZIndex(currentZIndex + 1)
  }

  const showCursor = () => {
    setCursor(true)
  }

  const hideCursor = () => {
    setCursor(false)
  }

  const cursorMove = event => {
    setCursorPosition({
      x: event.pageX,
      y: event.pageY - window.scrollY,
    })
    setCursor(true)
  }

  const data = useStaticQuery(graphql`
    query HeroQuery {
      prismicHomepage {
        data {
          hero_images {
            cursor_color
            image {
              alt
              large {
                url
              }
              medium {
                url
              }
              small {
                url
              }
            }
            # vimeo_video_id {
            #   text
            # }
          }
        }
      }
    }
  `)

  const allImages =
    data.prismicHomepage.data.hero_images &&
    data.prismicHomepage.data.hero_images.map((node, index) => {
      return (
        <Styled.HeroSlide
          style={{
            zIndex: index === currentIndex ? currentZIndex : currentZIndex - 1,
          }}
          key={index}
        >
          {node?.vimeo_video_id?.text?.length >= 1 ? (
            <Styled.Video>
              <Styled.VideoWrapper>
                {vimeoAccepted && (
                  <iframe
                    title="Vimeo Player"
                    src={`https://player.vimeo.com/video/${node.vimeo_video_id.text}?background=1&autoplay=1&autopause=0&loop=1&byline=0&title=0&muted=1&portrait=0`}
                    width="1920"
                    height="1080"
                    frameBorder="0"
                    allow="autoplay"
                  />
                )}
              </Styled.VideoWrapper>
            </Styled.Video>
          ) : (
            <Styled.Image
              cursorColor={node.cursor_color}
              style={{ backgroundImage: `url(${node.image.large.url})` }}
            />
          )}
        </Styled.HeroSlide>
      )
    })

  const allMobileImages =
    data.prismicHomepage.data.hero_images &&
    data.prismicHomepage.data.hero_images.map((node, index) => {
      return (
        <Slide index={index}>
          {node?.vimeo_video_id?.text?.length >= 1 ? (
            <Styled.Video>
              <Styled.VideoWrapper>
                {vimeoAccepted && (
                  <iframe
                    title="Vimeo Player"
                    src={`https://player.vimeo.com/video/${node.vimeo_video_id.text}?background=1&autoplay=1&autopause=0&loop=1&byline=0&title=0&muted=1&portrait=0`}
                    width="1920"
                    height="1080"
                    frameBorder="0"
                    allow="autoplay"
                  />
                )}
              </Styled.VideoWrapper>
            </Styled.Video>
          ) : (
            <Styled.Image
              cursorColor={node.cursor_color}
              style={{ backgroundImage: `url(${node.image.large.url})` }}
            />
          )}
        </Slide>
      )
    })

  return (
    <>
      <Styled.Hero
        style={{
          pointerEvents: heroPointerEvents,
          height: heroHeight + "px",
        }}
        onClick={goToNextSlide}
        onMouseOver={showCursor}
        onMouseMove={cursorMove}
        onMouseOut={hideCursor}
      >
        <Arrow
          visibility={cursor ? 1 : 0}
          positionX={cursorPosition.x + "px"}
          positionY={cursorPosition.y + "px"}
          mobileVisibility={false}
        />
        {allImages}
        <HeroFooter />
      </Styled.Hero>
      <Styled.HeroTouch style={{ height: heroHeight + "px" }}>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          totalSlides={allMobileImages.length}
          infinite={true}
          dragStep={1}
        >
          <Slider>{allMobileImages}</Slider>
          <HeroFooter />
        </CarouselProvider>
      </Styled.HeroTouch>
    </>
  )
}

export default Hero
