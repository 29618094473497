import styled from "styled-components"
import themeSettings from "../../styles/theme"

const Grid = styled.section`
  width: 100vw;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 414px) {
    grid-template-columns: 1fr;
    box-sizing: border-box;
    padding: ${themeSettings.mobileMargins.page};
  }
`

export default {
  Grid
}